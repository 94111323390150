<template>
  <div v-if="loadingTracking">
    <div class="card">
      <div class="card-body">
        <el-skeleton :rows="3" animated />
      </div>
    </div>
  </div>
  <div v-else>
    <timeline-list :last-tracking-list="lastTrackingList" title="Riwayat Pesanan">
      <div class="row">
        <div class="col-md-6">
          <div>
            <!-- trackingList -->
            <span v-for="list in trackingList" :key="list.id">
              <span v-if="list.status == 'Completed'">
                <TimelineItem
                  :icon="{ component: 'ni ni-check-bold', color: 'info' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else-if="list.status == 'Successful Delivery'">
                <TimelineItem
                  :icon="{ component: 'ni ni-trophy', color: 'success' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else-if="list.status == 'Cancelled'">
                <TimelineItem
                  :icon="{ component: 'ni ni-fat-remove', color: 'danger' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else-if="list.status == 'Pending Pickup'">
                <TimelineItem
                  :icon="{ component: 'ni ni-time-alarm', color: 'primary' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else-if="list.status == 'Parcel Size'">
                <TimelineItem
                  :icon="{ component: 'ni ni-settings', color: 'primary' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else-if="list.status == 'Parcel Measurements Update'">
                <TimelineItem
                  :icon="{ component: 'ni ni-settings', color: 'primary' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else-if="list.status == 'Parcel Weight'">
                <TimelineItem
                  :icon="{ component: 'ni ni-settings', color: 'primary' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
              <span v-else>
                <TimelineItem
                  :icon="{ component: 'ni ni-delivery-fast', color: 'warning' }"
                  :title="list.status"
                  :date-time="list.timestamp"
                  :description="list.comments"
                />
              </span>
            </span>
            <TimelineItem
              :icon="{ component: 'ni ni-app', color: 'info' }"
              title="Pesanan dibuat"
              :date-time="dataPackage.created_at"
              :description="dataPackage.items_description"
              :badges="[label_badges_1, label_badges_2]"
            />
          </div>
        </div>
        <div class="col-md-6">...</div>
      </div>
    </timeline-list>
  </div>
</template>

<script>
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import { ElSkeleton } from "element-plus";
import axios from "axios";
export default {
  name: "CourierTrackingProgress",
  components: {
    TimelineList,
    TimelineItem,
    ElSkeleton,
  },
  props: {
    dataPackage: { required: true, type: Object },
  },
  data() {
    return {
      urlTracking: "/api/v2/tracking_parcel",
      label_badges_1: "Dropoff",
      label_badges_2: "Non COD",
      loadingTracking: true,
      trackingList: [],
      lastTrackingList: [],
      lastStatus: "",
    };
  },
  created() {
    this.checkBadges();
    this.trackingProgress();
  },
  methods: {
    checkBadges() {
      //console.log('apakah cod/pickup : '+JSON.stringify(this.dataPackage))
      if (this.dataPackage.is_pickup_required == true) {
        this.label_badges_1 = "Pickup";
      }
      if (this.dataPackage.cash_on_delivery != null) {
        this.label_badges_2 = "COD";
      }
    },
    async trackingProgress() {
      try {
        this.loadingTracking = true;
        const response = await axios({
          method: "get",
          url:
            this.urlTracking + "/" + this.dataPackage["merchant_order_number"],
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.trackingList = response.data.data.tracking_list;
        this.lastTrackingList = this.trackingList[0];
        //console.log(this.lastTrackingList);
        this.lastStatus = response.data.data.last_status;
        this.$emit("newstatuscallback", this.lastStatus);

        this.loadingTracking = false;
      } catch (error) {
        this.loadingTracking = false;
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
