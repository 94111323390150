<template>
  <div class="card">
    <div class="card-body" style="padding-top: 40px; padding-bottom: 40px">
      <div class="row">
        <div class="col-md-4">
          <CourierTrackingStep :status="dataPackage.status" />
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Status Paket</b><br />
            <TrackParcelStatus
              :status="dataPackage.status"
              :message="dataPackage.error_message"
            />
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Perkiraan Waktu Pengiriman</b><br />
            {{ dataPackage.delivery_start_date }}
          </small>
        </div>
      </div>
    </div>
  </div>
  <br />
  <CourierTrackingProgress
    :data-package="dataPackage"
    @newstatuscallback="handleNewStatus"
  />
  <br />
  <div class="card">
    <div class="card-body" style="padding-top: 40px; padding-bottom: 40px">
      <h6>DETAIL PAKET</h6>
      <div class="row">
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Jenis Pengiriman</b><br />
            {{ dataPackage.service_level }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>COD</b><br />
            {{ dataPackage.display_cod }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Diasuransikan</b><br />
            <span v-if="dataPackage.is_insurance_required == 1">Ya</span>
            <span v-else>Tidak</span>
          </small>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Instruksi untuk Kurir</b><br />
            {{ dataPackage.delivery_instructions }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Isi dalam paket</b><br />
            {{ dataPackage.items_description }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Barang berbahaya?</b><br />
            <span v-if="dataPackage.items_is_dangerous_good == 1">Ya</span>
            <span v-else>Tidak</span>
          </small>
        </div>
      </div>
      <hr class="border border-primary border-1 opacity-1" />
      <div class="row">
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Nomor Tracking</b><br />
            {{ dataPackage.tracking_number }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Ukuran Paket</b><br />
            {{ dataPackage.dimensions_size }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Berat Paket</b><br />
            {{ dataPackage.dimensions_weight }}
          </small>
        </div>
      </div>
      <hr class="border border-primary border-1 opacity-1" />
      <div class="row">
        <div class="col-md-8">
          <small class="text-secondary">
            <b>Alamat Penjemputan</b><br />
            {{ dataPackage.from_address_1 }}<br />
            {{ dataPackage.origin }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Total Biaya (termasuk COD)</b><br />
            <span v-if="dataPackage.cash_on_delivery > 0">
              {{ dataPackage.display_cod }}
            </span>
          </small>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="card">
    <div class="card-body" style="padding-top: 40px; padding-bottom: 40px">
      <h6>DETAIL PENERIMA</h6>
      <div class="row">
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Nama</b><br />
            {{ dataPackage.to_name }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Telepon</b><br />
            {{ dataPackage.to_phone_number }}
          </small>
        </div>
        <div class="col-md-4">
          <small class="text-secondary">
            <b>Email</b><br />
            {{ dataPackage.to_email }}
          </small>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <small class="text-secondary">
              <b>Alamat</b><br />
              {{ dataPackage.to_address_1 }}<br />
              {{ dataPackage.destination }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="card">
    <div class="card-body" style="padding-top: 40px; padding-bottom: 40px">
      <span v-if="dataPackage.status == 'Error'">
        <span v-if="dataPackage.data_bulk_list_package != ''">
          <button class="btn btn-primary btn-lg" @click.prevent="showEditForm(dataPackage)"><i class="fas fa-edit"></i> Edit Data</button>
        </span>
      </span>
      <span v-else>
        <PrintAirWayBillButton :data="dataPackage" />&nbsp;&nbsp;&nbsp;
        <CancelOrderButton
          :data="dataPackage"
          @successcallback="handleSuccessCancelCallback"
        />
      </span>
    </div>
  </div>
</template>

<script>
import CourierTrackingStep from "@/components/CourierTrackingStep.vue";
import CourierTrackingProgress from "@/components/CourierTrackingProgress.vue";
import TrackParcelStatus from "@/components/TrackParcelStatus.vue";
import CancelOrderButton from "@/components/CancelOrderButton.vue";
import PrintAirWayBillButton from "@/components/PrintAirWayBillButton.vue";
export default {
  name: "ViewPackageDetail",
  components: {
    CourierTrackingStep,
    CourierTrackingProgress,
    CancelOrderButton,
    PrintAirWayBillButton,
    TrackParcelStatus,
  },
  props: {
    dataPackage: { required: true, type: Object },
    is_admin: { required: false, default: false, type: Boolean }
  },
  data() {
    return {
      showCourierTrackingStep: true,
    };
  },
  methods: {
    handleSuccessCancelCallback(values) {
      this.$emit("successcallback", values);
    },
    handleNewStatus(values) {
      this.$emit("newstatuscallback", values);
    },

    async showEditForm(row) {
      let send_package_id =  row.id;
      let bulk_list_package_id = row.data_bulk_list_package.bulk_list_package_id;
      let data = row.data_bulk_list_package.extract_column_value;
      let destination_address = row.destination_address;
      
      let param_is_admin = 0;
      if(this.is_admin == true) {
        param_is_admin = 1;
      }
      // if edit
      this.$router.push({
        name: "CreateSendPackages",
        params: {
          mode: "edit",
          is_admin: param_is_admin,
          send_package_id: send_package_id,
          bulk_list_package_id: bulk_list_package_id,
          data: JSON.stringify(data),
          destination_address: JSON.stringify(destination_address),
        },
      });
    },
  },
};
</script>

<style></style>
