<template>
  <div class="multisteps-form__progress">
    <button
      class="multisteps-form__progress-btn"
      :class="step01"
      type="button"
      title="Pengirim"
    >
      <small>Pengirim</small>
    </button>
    <button
      class="multisteps-form__progress-btn"
      :class="step02"
      type="button"
      title="Kurir"
    >
      <small>Kurir</small>
    </button>
    <button
      class="multisteps-form__progress-btn"
      :class="step03"
      type="button"
      title="Penerima"
    >
      <small>Penerima</small>
    </button>
  </div>
</template>

<script>
export default {
  name: "CourierTrackingStep",
  props: {
    status: { required: false, type: String },
  },
  data() {
    return {
      step01: "",
      step02: "",
      step03: "",
    };
  },
  created() {
    this.TrackingStep();
  },
  methods: {
    TrackingStep() {
      switch (this.status) {
        case "":
          this.Step01Active();
          break;
        case null:
          this.Step01Active();
          break;
        case "Created":
          this.Step01Active();
          break;
        case "Cancelled":
          this.Step01Active();
          break;
        case "Error":
          this.Step01Active();
          break;
        case "Pending Pickup":
          this.Step01Active();
          break;
        case "Pickup Fail":
          this.Step01Active();
          break;
        case "Completed":
          this.Step03Active();
          break;
        case "Successful Delivery":
          this.Step03Active();
          break;
        default:
          this.Step02Active();
          break;
      }
    },
    Step01Active() {
      this.step01 = "js-active position-relative";
      this.step02 = "";
      this.step03 = "";
    },
    Step02Active() {
      this.step01 = "js-active position-relative";
      this.step02 = "js-active position-relative";
      this.step03 = "";
    },
    Step03Active() {
      this.step01 = "js-active position-relative";
      this.step02 = "js-active position-relative";
      this.step03 = "js-active position-relative";
    },
  },
};
</script>

<style></style>
