<template>
  <span v-if="data.status == 'Pending'"> &nbsp; </span>
  <span v-else-if="data.status === null"> &nbsp; </span>
  <span v-else-if="data.status == 'Cancelled'"> &nbsp; </span>
  <span v-else-if="data.status == 'Order Not Found'"> &nbsp; </span>
  <span v-else>
    <argon-button
      variant="outline"
      color="primary"
      :loading="isLoading"
      @click.prevent="handlePrintAirWayBill"
      ><i class="fas fa-print" style="font-size: 18px"></i>&nbsp;&nbsp;Print
      Airway Bill</argon-button
    >
  </span>
</template>

<script>
import axios from "axios";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from "sweetalert2";
export default {
  name: "PrintAirWayBillButton",
  components: {
    ArgonButton,
  },
  props: {
    data: { required: true, type: Object },
  },
  data() {
    return {
      urlPDFAirwayBill: "/api/v1/pdf/airwaybill",
      urlNinjaAirwayBill: "/api/v1/airwaybill",
      basePath: axios.defaults.baseURL,
      isLoading: false,
    };
  },
  methods: {
    handlePrintAirWayBill() {
      let process_type = "from own pdf";
      //let process_type = "from own ninja";

      if (process_type == "from own pdf") {
        let url =
          this.basePath +
          this.urlPDFAirwayBill +
          "/" +
          this.data.merchant_order_number;
        window.open(url);
      } else if (process_type == "from own ninja") {
        this.PrintAirwayBillNinja();
      }
    },
    async PrintAirWayBillNinja() {
      try {
        this.isLoading = true;
        const response = await axios({
          methods: "get",
          url: this.urlNinjaAirwayBill + "/" + this.data.merchant_order_number,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log(response.data);
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
