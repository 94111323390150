<template>
  <span v-if="data.status == 'Created'">
    <argon-button
      color="danger"
      :loading="isLoading"
      @click.prevent="handleCancelOrder"
      ><i class="fas fa-trash" style="font-size: 18px">&nbsp;&nbsp;</i>Cancel
      Order</argon-button
    >
  </span>
  <span v-else-if="data.status == 'Pending Pickup'">
    <argon-button
      color="danger"
      :loading="isLoading"
      @click.prevent="handleCancelOrder"
      ><i class="fas fa-trash" style="font-size: 18px">&nbsp;&nbsp;</i>Cancel
      Order</argon-button
    >
  </span>
</template>

<script>
import axios from "axios";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from "sweetalert2";
export default {
  name: "CancelOrderButton",
  components: {
    ArgonButton,
  },
  props: {
    data: { required: true, type: Object },
  },
  data() {
    return {
      urlCancelOrder: "/api/v1/send_packages/cancelorder",
      isLoading: false,
    };
  },
  methods: {
    handleCancelOrder() {
      Swal.fire({
        title: "Konfirmasi!",
        html:
          `Yakin ingin membatalkan Pesanan ini!<br /><b>` +
          this.data.merchant_order_number +
          `</b>`,
        showCancelButton: true,
        confirmButtonText: "Yes, hapus!",
      }).then((result) => {
        if (result.value) {
          this.CancelOrder();
        }
      });
    },
    async CancelOrder() {
      try {
        this.isLoading = true;
        const response = await axios({
          methods: "get",
          url: this.urlCancelOrder + "/" + this.data.merchant_order_number,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log(response.data);
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          // this emit
          //this.$emit("successcallback", true);
          Swal.fire({
            title: "Success!",
            html: "Successfully cancel order",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });        
          // refresh page
          window.location.reload();  
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
